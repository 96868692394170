
import { useCallback, useEffect,useMemo , useRef, useState  } from 'react';
import { IoIosSettings } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';



import UsersCountIcon from '../../assets/images/users_count.svg';
import FireImage from '../../assets/images/fire_bg.png';
import PalmImage from '../../assets/images/palm.png';
import DollarCoinImage from '../../assets/images/dollorCoin.png';
import BoostImage from '../../assets/images/boost.png';
import {CheckUserCoins,GetTotalUsers,IncreaseClicksAction,UpdateLocalCoinsAction,} from '../../redux/actions/gameActions';
import {ClickCountKey,footerHeight,UserLevelImages,} from "../../general.js";
import { preventCloseWithSwipe } from "../../utils/preventCloseWithSwipe.js";
import { GetLevelsAction } from "../../redux/actions/leaderBoardActions.js";
import { useBackButton } from "../../hooks/useBackButton.jsx";
import { useCoins } from "../../hooks/useCoins.js";
import { getPopupMessageAction } from "../../redux/actions/messageActions.js";
import "./style.css";
import { useEnergy } from '../../hooks/useEnergy.js';
import { GameSettingsClickAction,levelUpAnalyticsAction,GameLeaderboardClickAction } from '../../redux/actions/googleAnalyticsActions.js';
import AvatarCanvas from '../Avatar/AvatarCanvas.jsx';
import { dispatchErrorToast } from '../../utils/functions.js';


const pointsToAdd = 1;
const clicksPerCall = 100;
const _energy = 1000;

const BaseLionTransform = 'translateX(-50%)';
const MaxRotateDeg = 30;

const Homepage = () => {
  
  useBackButton('/home', 'close');
  const { t, i18n } = useTranslation();
  const AuthState = useSelector((state) => state.Auth);
  const GameState = useSelector((state) => state.Game);
  const { user } = AuthState;
  const { clicks, totalUsers } = GameState;
  const { currentCoins, currentCoinsRef } = useCoins();
  const { energy, decrease: decreaseEnergy } = useEnergy();
  const [displayedClicks, setDisplayedClicks] = useState([]);
  const [userLevel, setUserLevel] = useState(null);
  const [userLevelName, setUserLevelName] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isRotatingRef = useRef(false);


  const minRange = parseInt(user?.levl_min_range);
  const maxRange = parseInt(user?.levl_max_range);
  const barWidth = ((currentCoins - minRange) / (maxRange - minRange)) * 100;

  const handleBoostRewardClick = () => navigate(`/boost-rewards`);
  const redirectToSettings = () => {
    dispatch(GameSettingsClickAction());
    navigate(`/settings`);
  };
  const redirectToLeaderBoard = () => {
    dispatch(GameLeaderboardClickAction());
    navigate(`/leaderboard`);
  }

  const getLevelName = (user) => {
    if (!user || !user.level_name) {
      return '';
    }

    const { level_name } = user;

    if (typeof level_name === 'object') {
      const lang = i18n?.language?.substring(0, 2)?.toLowerCase() || 'en';
      return level_name[lang] || level_name['en'] || '';
    }

    return level_name;
  };

  const handleClickTest = () => {
    //dispatchErrorToast(dispatch);      
    const avatar = document.getElementById('avatarCanvas');
    
    // Add the shake class to trigger the animation
    avatar.classList.add('shake');
    
    // Remove the shake class after the animation ends
    avatar.addEventListener('animationend', () => {
      avatar.classList.remove('shake');
    }, { once: true });
  };
  

  useEffect(() => {
    dispatch(CheckUserCoins());
    dispatch(GetLevelsAction());
    dispatch(getPopupMessageAction());
    dispatch(GetTotalUsers());
    preventCloseWithSwipe();
  }, []);

  useEffect(() => {
    localStorage.setItem(ClickCountKey, clicks);
    if (clicks % clicksPerCall === 0) {
      //handleReportCoins();
    }
  }, [clicks]);

  useEffect(() => {
    if (user) { 
      dispatch(levelUpAnalyticsAction({level_name:user?.level_name?.en}));
      setUserLevel(user.level);
    }
  }, [user?.level]);

  useEffect(() => {
	console.log("Debug","user",user);
    if (user) {
      const levelName = getLevelName(user);
      setUserLevelName(levelName);
    }
  }, [user, i18n.language]);

  const handleClick = (e) => {	
    if (e.touches.length === 0) return;
	if(GameState.energy <= 0 ) return;

    const touch = e.touches[e.touches.length - 1];
    const x = touch.clientX;
    const mainPanel = touch.target.closest('#main_panel');
    const offsetY = mainPanel.offsetTop;
    const y = touch.clientY - offsetY;

    if (localStorage.getItem('is_haptic') === 'true') {
      window.Telegram?.WebApp?.HapticFeedback?.impactOccurred('heavy');
    }

    dispatch(UpdateLocalCoinsAction(currentCoinsRef.current + pointsToAdd));
    dispatch(IncreaseClicksAction());
    decreaseEnergy();
    setDisplayedClicks([...displayedClicks, { id: Date.now(), x, y }]);

    if (!isRotatingRef.current) {
      const lionImage = document.getElementById('main-lion-image');
      const rotateX =
        ((y - mainPanel.offsetHeight / 2) / mainPanel.offsetHeight) *
        MaxRotateDeg;
      const rotateY =
        (-(x - mainPanel.offsetWidth / 2) / mainPanel.offsetWidth) *
        MaxRotateDeg;
      lionImage.style.transform = `${BaseLionTransform} rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
      isRotatingRef.current = true;
      setTimeout(() => {
        lionImage.style.transform = BaseLionTransform;
        isRotatingRef.current = false;
      }, 100);
    }
  };

  const handleAnimationEnd = (id) => {
    setDisplayedClicks((prevClicks) =>
      prevClicks.filter((click) => click.id !== id)
    );
  };


  const mainImageUrl = useMemo(() => {

    if (userLevel <= UserLevelImages.length) {
      return UserLevelImages[userLevel - 1];
    }
    return UserLevelImages[0];

  });
               
  //console.log(user?.username, user?.avatar);

  return (
    <div
      className="panel_home w-full h-full text-white font-bold relative flex flex-col"
      style={{ height: `calc(100dvh - ${footerHeight}px)` }}
    >
      {/* TOP SECTION START - USERNAME AND SETTING ROUTE */}
      <div className="relative flex justify-between items-center text-xl w-full p-3">
        <div className="flex justify-between items-center gap-1">
          <div onClick={handleClickTest}>
            <AvatarCanvas
              key=""
              onlyView={true}
              id="avatarCanvas" // Set the ID here
              layerCode={user?.avatar ? user?.avatar : ''}
              width={35}
              height={35}
              source={'_assad'}
            />
          </div>
          <span className="text-[10px] max-w-[50px] max-h-[30px] text-wrap leading-none text-clip overflow-hidden">
            {user?.username}
          </span>
        </div>
        <div
          className="flex justify-between items-center gap-1 h-[25px] rounded-[0_10px_10px_0] p-[0_2px]"
          onClick={redirectToSettings}
        >
          <span className="text-[30px]">
            <IoIosSettings className="text-[30px] text-[#939393]" />
          </span>
        </div>
        {!!totalUsers && (
          <div
            className="absolute top-0 left-1/2 -translate-x-1/2 h-[54px] flex flex-row rounded-b-[30px] items-center justify-content-center px-6 py-4 gap-2"
            style={{
              background:
                'linear-gradient(0deg, rgba(255,255,255,0.12) 0%, rgba(207,207,207,0.12) 100%)',
            }}
          >
            <img src={UsersCountIcon} alt="" className="w-[30px] h-[30px]" />
            <div className="flex flex-col items-center leading-none">
              <span className="text-lg font-bold text-[#f2f2f2]">
                {totalUsers.toLocaleString()}
              </span>
              <span className="text-[9px] font-medium text-[#f2f2f2]">
                {t('total_users.title')}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* TOP SECTION END - USERNAME И SETTING ROUTE */}

      {/* COIN COUNTER SECTION START - POINTS, COUNTER И BOOST  */}
      <div className="flex justify-center items-center">
        <div className="flex justify-between items-start gap-[5px] starpoint w-80">
          <div className="text-center">
            <img
              src={PalmImage}
              alt="energy"
              className="w-16 h-16 mx-auto rounded-full"
            />
            <p className="text-[10px] text-[#ffffffa3]">
              {energy}/{_energy}
            </p>
          </div>
          <div className="text-center">
            <img
              src={DollarCoinImage}
              alt="Exchange"
              className="w-[80px] h-[75px] mx-auto rounded-full"
            />
          </div>
          <div className="text-center" onClick={handleBoostRewardClick}>
            <img
              src={BoostImage}
              alt="Boost"
              className="w-16 h-16 mx-auto rounded-full"
            />
            <p className="text-[10px] text-[#ffffffa3]">{t('main.roar')}</p>
          </div>
        </div>
      </div>
      <div className="w-full">
        <p className="text-[30px] text-white text-center">
          {currentCoins ? currentCoins.toLocaleString() : 0}
        </p>
      </div>
      {/* COIN COUNTER SECTION END - POINTS, COUNTER И BOOST  */}


      {/* PLATFORM SECTION START - LION  */}
      <div
        className="relative flex flex-col justify-center grow w-full font-bold text-5xl"
        id="main_panel"
      >
        <div
          className="w-full h-full flex justify-center"
          onTouchStart={handleClick}
        >
          <img
            src={FireImage}
            alt="FireImage"
            className="absolute w-full h-full bottom-0"
          />
          <img
            id="main-lion-image"
            src={mainImageUrl}
            alt="MonkeyIcon"
            className="absolute max-h-[75%] left-1/2 bottom-[12%]"
            style={{ transform: BaseLionTransform }}
          />
          <div>
            {displayedClicks.map((click) => (
              <div
                key={click.id}
                className="absolute"
                style={{
                  top: `${click.y - 28}px`,
                  left: `${click.x - 28}px`,
                  opacity: 0,
                  animation: `float 0.5s ease-out`,
                }}
                onAnimationEnd={() => handleAnimationEnd(click.id)}
              >
                +{pointsToAdd}
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* PLATFORM SECTION END - LION  */}

      {/* LEVEL SECTION START  */}
      <div
        className="mt-auto w-full z-50 px-[30px]"
        onClick={redirectToLeaderBoard}
      >
        <div className="flex items-center justify-between mb-1">
          <p className="text-[#ffffffa3] text-[10px]">{`${userLevelName} >`}</p>
          <p className="text-[#ffffffa3] text-[10px]">{userLevel}/10</p>
        </div>
        <div className="relative h-[10px] rounded-full overflow-hidden bg-[#5b5b5b]">
          <div
            className="absolute top-0 bottom-0 left-0 rounded-full bg-gradient-to-r from-[#5ff44a] to-[#1e7a03]"
            style={{ width: `${barWidth}%` }}
          ></div>
        </div>
      </div>
      {/* LEVEL SECTION END  */}
    </div>
  );
};

export default Homepage;
